import React, { useEffect } from 'react';
import config from "../../config/index"
import Spinner from '../../components/Spinner/Spinner';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';


const RedirectPage = ({}) => {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = config.agencias2Login;
    }, 1000);
  }, []);

  return (
    <div style={{margin: '50px 50vw'}}>
       <Spinner  size={50}/>
    </div>
  )
}

export default RedirectPage;